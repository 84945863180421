import React, { useState, useEffect, useMemo } from 'react';
import InputField from '../components/FaroCreationComponents/InputField';
import CategorySelection from '../components/FaroCreationComponents/CategorySelection';
import TimeSelection from '../components/FaroCreationComponents/TimeSelection';
import CapacitySelection from '../components/FaroCreationComponents/CapacitySelection';
import ContactInfo from '../components/FaroCreationComponents/ContactInfo';
import MediaUpload from '../components/FaroCreationComponents/MediaUpload';
import Booking from '../components/FaroCreationComponents/Booking';
import ButtonContainer from '../components/FaroCreationComponents/ButtonContainer';
import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { registerFaro, updateFaro } from '../functions/faro';
import { supabase } from '../lib/helper/supabaseClient';
import { formatDateToISO, subtractDateTimes } from '../lib/helper/timeConvert';
import PaymentDialog from '../components/FaroCreationComponents/PaymentDialog';


const EventForm = ({ faroData }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [formKey, setFormKey] = useState(Date.now());
  const artist = useMemo(
    () => location.state?.artist || { rate: 150000 },
    [location.state?.artist]
  );

  const [openDialog, setOpenDialog] = useState(false); // For error popup
  const [dialogMessage, setDialogMessage] = useState(''); // Dialog message
  const [formData, setFormData] = useState({
    eventName: '',
    eventLocation: '',
    eventSpecification: '',
    coverCharge: '',
    category: '',
    time: '',
    toTime: '',
    capacity: '',
    contactInfo: '',
    media: null,
    booking: artist,
    fullName: '',
    phoneNumber: '',
    emailAddress: '',
    valor: 0,
  });

  useEffect(() => {
    if (faroData) {
      console.log('Faro data details:', faroData.details);
      setFormData({
        eventName: faroData.name || '',
        eventLocation: faroData.address || '',
        coverCharge: faroData.details.coverCharge || '',
        category: faroData.details.category || '',
        eventSpecification: faroData.details.eventSpecification || '',
        time: faroData.eventDate || '',
        toTime: faroData.toTime || '',
        capacity: faroData.details.capacity || '',
        contactInfo: faroData.contactInfo || '',
        media: faroData.media || null,
        fullName: faroData.details.fullName || '',
        phoneNumber: faroData.details.phoneNumber || '',
        emailAddress: faroData.details.emailAddress || '',
        valor: faroData.valor || 0,
      });
      console.log('Faro data:', faroData);
    }
  }, [faroData]);




  const [errors, setErrors] = useState({});

  const validateEventName = () => {
    if (formData.eventName.trim() === '') {
      return 'El nombre del evento es obligatorio';
    }
    return '';
  };

  const validateEventLocation = () => {
    if (formData.eventLocation.trim() === '') {
      return 'La ubicación del evento es obligatoria';
    }
    return '';
  };

  const validateCoverCharge = () => {
    if (
      formData.coverCharge === '' ||
      isNaN(formData.coverCharge) ||
      parseFloat(formData.coverCharge) < 0
    ) {
      return 'Se requiere una tarifa de entrada válida';
    }
    return '';
  };

  const validateCategory = () => {
    if (formData.category === '') {
      return 'La categoría es obligatoria';
    }
    return '';
  };

  const validateTime = () => {
    if (formData.time === '') {
      return 'La hora es obligatoria';
    }
    return '';
  };

  const validateCapacity = () => {
    if (
      formData.capacity === '' ||
      isNaN(formData.capacity) ||
      parseInt(formData.capacity) <= 0
    ) {
      return 'Se requiere una capacidad válida';
    }
    return '';
  };


  const validateContactInfo = () => {
    const { fullName, phoneNumber, emailAddress } = formData;
    if (fullName.trim() === '') return 'El nombre completo es obligatorio';
    if (phoneNumber.trim() === '') return 'El número de teléfono es obligatorio';
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress))
      return 'Se requiere una dirección de correo electrónico válida';
    return '';
  };


  const buttons = [{ text: 'Continuar y pagar', color: '#AC8C46' }];

  const CreateFaro = async (e) => {
    e.preventDefault();

    const newErrors = {
      eventName: validateEventName(),
      eventLocation: validateEventLocation(),
      coverCharge: validateCoverCharge(),
      category: validateCategory(),
      time: validateTime(),
      capacity: validateCapacity(),
      contactInfo: validateContactInfo(),
    };

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error !== '')) {
      const errorMessages = Object.values(newErrors)
        .filter((error) => error !== '')
        .join('\n');

      setDialogMessage(`Validación fallida:\n${errorMessages}`);
      setOpenDialog(true);

      console.log('Validación fallida:', newErrors);
      return;
    }

    const now = new Date();
    const created_at_time = formatDateToISO(now);
    const duration = subtractDateTimes(formData.time, formData.toTime);
    const {
      data: { user },
      error,
    } = await supabase.auth.getUser();
    console.log('formData.time:', formData.time);

    try {
      await registerFaro(
        created_at_time,
        formData.time,
        formData.valor,
        formData,
        user.id,
        artist.id,
        null,
        formData.eventName,
        formData.eventLocation,
        formData.toTime
      );
      console.log('Form data:', formData);
      navigate('/dashboard/client');

      if (error) {
        throw new Error('Error al obtener el usuario: ' + error.message);
      }

      if (!user) {
        throw new Error('No se encontró un usuario autenticado');
      }
      console.log(user);
    } catch (error) {
      console.error('Error completo:', error);
      alert(
        'Ocurrió un error durante la creación del Faro. Revisa la consola para más detalles.'
      );
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleCategoryChange = (category) => {
    setFormData((prevData) => ({
      ...prevData,
      category,
    }));
    console.log('Category:', category);
  };

  const handleTimeChange = (time) => {
    setFormData((prevData) => ({
      ...prevData,
      time,
    }));
  };

  const handleToTimeChange = (toTime) => {
    setFormData((prevData) => ({
      ...prevData,
      toTime,
    }));
  };

  const handleCapacityChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleContactInfoChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleMediaUpload = (newFiles) => {
    setFiles(newFiles);
  };

  const closeDialog = () => setOpenDialog(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = async (e) => {
    e.preventDefault();

    if (faroData) {

      try {
        await actualizarFaro(e);
      } catch (error) {
        console.error('Error al actualizar el Faro:', error);
      }
    } else {
      setOpen(true);
    }
  };

  const actualizarFaro = async (e) => {
    e.preventDefault();

    const newErrors = {
      eventName: validateEventName(),
      eventLocation: validateEventLocation(),
      coverCharge: validateCoverCharge(),
      time: validateTime(),
      capacity: validateCapacity(),
      contactInfo: validateContactInfo(),
    };

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error !== '')) {
      const errorMessages = Object.values(newErrors)
        .filter((error) => error !== '')
        .join('\n');

      setDialogMessage(`Validation failed:\n${errorMessages}`);
      setOpenDialog(true);

      console.log('Validation failed:', newErrors);
      return;
    }

    const now = new Date();
    const duration = subtractDateTimes(formData.time, formData.toTime);
    const {
      data: { user },
      error,
    } = await supabase.auth.getUser();

    try {
      await updateFaro(
        faroData.id,
        formData.time,
        formData.valor,
        formData,
        user.id,
        artist.id,
        null,
        formData.eventName,
        formData.eventLocation,
        formData.toTime
      );

      console.log('Faro actualizado:', formData);
      navigate('/dashboard/client');

      if (error) {
        throw new Error('Error al obtener el usuario: ' + error.message);
      }

      if (!user) {
        throw new Error('No se encontró un usuario autenticado');
      }
      console.log(user);
    } catch (error) {
      console.error('Error al actualizar el Faro:', error);
      alert(
        'Ocurrió un error durante la actualización del Faro. Revisa la consola para más detalles.'
      );
    }
  };



  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (formData.time && formData.toTime) {
      const startTime = new Date(formData.time);
      const endTime = new Date(formData.toTime);
      const durationMs = endTime - startTime;

      if (durationMs > 0) {
        const durationMinutes = durationMs / (1000 * 60);
        const valor = (artist.rate * durationMinutes) * 1.3 / 60;
        setFormData((prevData) => ({
          ...prevData,
          valor,
        }));
      } else {
        setDialogMessage(
          'La hora de finalización debe ser después de la hora de inicio.'
        );
        setOpenDialog(true);
      }
    }
  }, [formData.time, formData.toTime, artist.rate]);

  return (
    <form onSubmit={CreateFaro} key={formKey}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 3,
          alignItems: 'flex-start',
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <Dialog open={openDialog} onClose={closeDialog}>
          <DialogTitle>Error</DialogTitle>
          <DialogContent>
            <Typography>{dialogMessage}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        <InputField
          label="¿Cuál es el nombre de tu evento?"
          id="eventName"
          required
          value={formData.eventName}
          onChange={handleInputChange}
          error={!!errors.eventName}
          helperText={errors.eventName}
        />

        <CategorySelection
          value={formData.category}
          onChange={handleCategoryChange}
          error={!!errors.category}
        />

        <InputField
          label="¿Dónde es la ubicación del evento?"
          id="eventLocation"
          required
          value={formData.eventLocation}
          onChange={handleInputChange}
          error={!!errors.eventLocation}
          helperText={errors.eventLocation}
        />

        <InputField
          label="¿Alguna otra especificación?"
          id="eventSpecification"
          value={formData.eventSpecification}
          onChange={handleInputChange}
        />

        <TimeSelection
          value={formData.time}
          onChange={handleTimeChange}
          toValue={formData.toTime}
          onChangeTo={handleToTimeChange}
        />

        <CapacitySelection
          value={formData.capacity}
          onChange={(newValue) =>
            setFormData({ ...formData, capacity: newValue })
          }
        />

        <InputField
          label="¿Cuál es el precio de la entrada al evento?"
          id="coverCharge"
          required
          value={formData.coverCharge}
          onChange={handleInputChange}
        />

        <ContactInfo
          formData={formData}
          handleChange={handleContactInfoChange}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <MediaUpload onUpload={handleMediaUpload} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Booking artist={artist} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            mb: '50px',
            mt: '50px',
          }}
        >
          <Box display="flex" flexWrap="wrap" gap={5} alignItems="center">
            {buttons.map((button, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: { xs: '240px', sm: '407px' },
                  minHeight: '50px',
                }}
              >
                <Button
                  variant="contained"
                  type="button"
                  onClick={handleClickOpen}
                  sx={{
                    padding: '16px',
                    borderRadius: '8px',
                    width: '100%',
                    textTransform: 'none',
                    backgroundColor: button.color,
                  }}
                >
                  <Typography
                    variant="button"
                    fontSize="16px"
                    fontWeight="500"
                  >
                    {faroData ? ('Continuar') : ('Continuar y pagar')}
                  </Typography>
                </Button>
              </Box>
            ))}

            <PaymentDialog
              open={open}
              handleClose={handleClose}
              Valor={String((Number(formData.valor) || 0).toFixed(2))}
              onNext={CreateFaro}
            />
          </Box>
        </Box>
      </Box>
    </form>

  );
};

export default EventForm;
