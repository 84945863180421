import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import guitarBackground from "../assets/images/backgroundGuitar.png";
import popIcon from "../assets/images/categories/pop.png";
import rockIcon from "../assets/images/categories/rock.png";
import reggaetonIcon from "../assets/images/categories/reggaeton.png";
import acousticIcon from "../assets/images/categories/acoustic.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/Header";
import SearchBar from "../components/SearchBar/SearchBar";
import { supabase } from "../lib/helper/supabaseClient";
import { getUserRoleByID } from "../functions/general";

// Styled Components
const Root = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  backgroundColor: "#0e0e0e",
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  backgroundImage: `url(${guitarBackground})`,
  backgroundSize: "50%",
  backgroundPosition: "top center",
  backgroundRepeat: "no-repeat",
}));

const HeroContent = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: theme.spacing(8),
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  borderRadius: theme.shape.borderRadius,
}));

const ServicesSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 4),
  backgroundColor: "#fff",
  color: "#000",
  textAlign: "center",
}));

const ServiceCard = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  textAlign: "center",
  "& img": {
    width: "60%",
    height: "auto",
    objectFit: "cover",
    borderRadius: theme.shape.borderRadius,
  },
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 4),
  backgroundColor: "#0e0e0e",
  color: "#fff",
  textAlign: "center",
  backgroundImage: `url(${guitarBackground})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundBlendMode: "overlay",
  backgroundColor: "rgba(0, 0, 0, 0.8)",
}));

// Custom Arrow Components
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    />
  );
};

// Homepage Screen
const HomepageScreen = () => {
  const [userRole, setUserRole] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    // Function to check if a user is logged in and fetch the role
    const checkUser = async () => {
      // Fetch the logged-in user
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUser(user);

      // If the user is logged in, fetch their role
      if (user) {
        const userID = user.id;
        console.log("user id: ", userID)
        const { role } = await getUserRoleByID(userID); // Fetch role by user ID
        setUserRole(role); // Store the role
        console.log(`User role is: ${role}`);
        localStorage.setItem("userRole", role);
      } else {
        console.log("No user logged in.");
      }
    };

    checkUser();
  }, []);

  return (
    <div>
      <Root>
        <Header></Header>
        <HeroContent>
          <Typography variant="h3" component="h1" gutterBottom>
            Contrata tu artista{" "}
            <span style={{ color: "#af8e56" }}>por horas.</span>
          </Typography>
          <Typography variant="h6" component="p" gutterBottom>
            Descubre lugares que resuenen con tu música y den vida a tu presentación. Comienza tu búsqueda y deja que tu talento encuentre su{" "}
            <span style={{ color: "#af8e56" }}>escenario perfecto.</span>
          </Typography>

          <Box sx={{ display: "flex", width: "70%" }}>
            <SearchBar isHomePage={true} />
          </Box>
        </HeroContent>
      </Root>

      {/* Popular Services Section */}
      <ServicesSection>
        <Typography variant="h4" component="h2" gutterBottom>
          Servicios <span style={{ color: "#af8e56" }}>populares</span>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Ya sea que seas un lugar buscando talento o un artista listo para brillar, estas opciones populares te ayudan a conectar y crear experiencias inolvidables.
        </Typography>

        <Slider {...sliderSettings}>
          <ServiceCard>
            <img src={popIcon} alt="Pop" />
          </ServiceCard>
          <ServiceCard>
            <img src={rockIcon} alt="Rock" />
          </ServiceCard>
          <ServiceCard>
            <img src={acousticIcon} alt="Acoustic" />
          </ServiceCard>
          <ServiceCard>
            <img src={reggaetonIcon} alt="Reggaeton" />
          </ServiceCard>
        </Slider>
      </ServicesSection>

      {/* Footer Section */}
      <Footer>
        <Typography variant="h4" component="h2" gutterBottom>
          Ilumina tu camino. Encuentra el{" "}
          <span style={{ color: "#af8e56" }}>lugar perfecto</span> para actuar.
        </Typography>
      </Footer>
    </div>
  );
};

export default HomepageScreen;
