import React, { useState, useEffect } from 'react';
import { Box, Typography, Card } from '@mui/material';
import { styled } from '@mui/system';
import TimeSelection from '../FaroCreationComponents/TimeSelection';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#1e1e1e',  
  color: '#fff',               
  borderRadius: '15px',
  padding: theme.spacing(3),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',  
}));

const PriceCalculator = ({ rate }) => {
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (startTime && endTime) {
      const start = new Date(startTime);
      const end = new Date(endTime);
      const durationInHours = (end - start) / (1000 * 60 * 60);

      if (durationInHours > 0) {
        const totalCost = durationInHours * rate * 1.3;
        setPrice(totalCost.toFixed(2));
      } else {
        setPrice(0);
      }
    } else {
      setPrice(0);
    }
  }, [startTime, endTime, rate]);

  return (
    <StyledCard>
      <Typography variant="h5" gutterBottom>
        Calcular el precio de contratación
      </Typography>
      <TimeSelection
        value={startTime}
        onChange={setStartTime}
        toValue={endTime}
        onChangeTo={setEndTime}
        themeD="dark"
      />
      <Typography variant="h6" sx={{ mt: 2 }}>
        Total: ${price}
      </Typography>
    </StyledCard>
  );
};

export default PriceCalculator;
