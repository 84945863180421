import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Grid,
  Box,
  Select,
  MenuItem,
  Button,
  IconButton,
} from "@mui/material";
import { ChevronDown } from "lucide-react";
import SearchBar from "../components/SearchBar/SearchBar";
import ArtistCard from "../components/ArtistCard/ArtistCard";
import Header from "../components/Header/Header";
import { fetchAllArtists } from "../functions/general";
import { useLocation, useNavigate } from "react-router-dom";


export default function ArtistSearchScreen() {
  const [artists, setArtists] = useState([]);
  const location = useLocation();
  const [searchCriteriaPage, setSearchCriteriaPage] = useState(location.state?.searchCriteria || "");

  const handleSearch = (results) => {
    setArtists(results);
  };

  useEffect(() => {
    const fetchArtists = async () => {
      let results = await fetchAllArtists("artist");
      setArtists(results);
    };
    if(searchCriteriaPage === ""){
      fetchArtists();
    }
  }, []);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#f5f5f5", minHeight: "100vh" }}>
      <Header />
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        {/* Search Bar */}
        <SearchBar
          onSearch={handleSearch}
          searchCriteriaPage={searchCriteriaPage}
          setSearchCriteriaPage={setSearchCriteriaPage}
          artists={artists}
        />

        {/* Display Results */}
        {artists.length > 0 && (
          <>
            {/* Header Section with Results */}
            {
              searchCriteriaPage && (
                <Typography variant="h5" sx={{ mt: 4, fontWeight: "bold" }}>
                  Resultados para{" "}
                  <span style={{ color: "#af8e56" }}>{searchCriteriaPage}</span>
                </Typography>
              )

              // <Typography variant="h5" sx={{ mt: 4, fontWeight: 'bold' }}>
              //   Resultados para <span style={{ color: "#af8e56" }}>todos los artistas</span>
              // </Typography>
            }
            {/* <Typography variant="body2" sx={{ mt: 1, mb: 4 }}>
              Related: Ballad, Acoustic, Pop Rock, Rock
            </Typography> */}

            {/* Filter and Sorting Options */}
            
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 4,
              }}
            >
              <Box sx={{ display: "flex", gap: 2 }}>
                {/* Popularity Dropdown */}
                {/* <Select
                  value=""
                  displayEmpty
                  size="small"
                  sx={{ bgcolor: "#fff", borderRadius: 2, minWidth: 120 }}
                >
                  <MenuItem value="" disabled>
                    Popular
                  </MenuItem>
                  <MenuItem value="asc">Ascending</MenuItem>
                  <MenuItem value="desc">Descending</MenuItem>
                </Select> */}

                {/* Budget Dropdown */}
                {/* <Select
                  value=""
                  displayEmpty
                  size="small"
                  sx={{ bgcolor: "#fff", borderRadius: 2, minWidth: 120 }}
                >
                  <MenuItem value="" disabled>
                    Budget
                  </MenuItem>
                  <MenuItem value="low">Low to High</MenuItem>
                  <MenuItem value="high">High to Low</MenuItem>
                </Select> */}
              </Box>

              {/* Filters Button */}
              {/* <Button
                variant="outlined"
                endIcon={<ChevronDown />}
                sx={{ color: "#af8e56", textTransform: "none" }}
              >
                Filters
              </Button> */}
            </Box>

            {/* Artist Grid Section */}
            <Grid container spacing={3}>
              {artists.map((artist) => (
                <Grid item xs={12} sm={6} md={3} key={artist.id}>
                  <ArtistCard artist={artist} />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Container>
    </Box>
  );
}
