import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomepageScreen from './pages/homepageScreen';
import RegisterScreen from './pages/registerScreen';
import ArtistProfileScreen from './pages/artistProfileScreen';
import ArtistSearchScreen from './pages/artistSearchScreen';
import SigInScreen from './pages/SignInPage'
import CreateFaroPage from './pages/faroCreationScreen';
import ClientDashboard from './pages/clientDashboard';
function App() {
  return (
    <Router>
      <Routes>
        {/* Default route */}
        <Route path="/" element={<HomepageScreen />} />
        <Route path="/register" element={<RegisterScreen />} />
        <Route path="/profile/artist" element={<ArtistProfileScreen />} />
        <Route path="/search" element={<ArtistSearchScreen />} />
        <Route path="/login" element={<SigInScreen />} />
        <Route path="/faro_creation" element={<CreateFaroPage />} />
        <Route path="/faro_creation/:faroId" element={<CreateFaroPage />} />
        <Route path= "/dashboard/client" element={<ClientDashboard/>}></Route>
        {/* Other routes */}
        {/* Example: <Route path="/about" element={<AboutScreen />} /> */}
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
