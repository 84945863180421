import Slider from "react-slick";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Avatar ,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import ofarosLogo from "../../assets/images/logo.png";
import React, {useEffect, useState} from "react";
import { supabase } from "../../lib/helper/supabaseClient";
const AppBarStyled = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#1a1a1a",
}));

// const ToolbarStyled = styled(Toolbar)(({ theme }) => ({}));
const SearchButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  backgroundColor: "#af8e56",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#af8e56",
  },
}));
const Header = () => {
  const navigate = useNavigate();
  const [profilePic, setProfilePic] = useState("");
  const [user, setUser] = useState(null);
  // Fetch current user on component mount
  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user); // Set the user if they are logged in
      if (user) {
        const { data, error } = await supabase
          .from('artist')
          .select('profile_picture')
          .eq('id', user.id)
          .single();

        if (data) {
          setProfilePic(data.profile_picture);
        }
      }
    };

    fetchUser();

    // Optional: Listen for authentication state changes
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user || null);
      if (session?.user) {
        supabase
          .from('artist')
          .select('profile_picture')
          .eq('id', session.user.id)
          .single()
          .then(({ data }) => setProfilePic(data?.profile_picture || ""));
      } else {
        setProfilePic("");
      }

    });

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, []);

  

  return (
    <AppBarStyled position="static">
      <Box
        sx={{
          justifyContent: "space-between",
          paddingTop: 3,
          paddingBottom: 4,
          paddingRight: 20,
          paddingLeft: 20,
          display: "flex",
        }}
      >
        <Box display="flex" alignItems="center">
          <img
            src={ofarosLogo}
            alt="Ofaros Logo"
            style={{ height: "40px", marginRight: "10px" }}
          />
        </Box>
        <Box display="flex" gap={4}>
          <Button color="inherit" onClick={()=> navigate("/")}>Home</Button>
          <Button color="inherit" onClick={()=>navigate("/search")}>Artistas</Button>
          <Button color="inherit" onClick={()=>navigate("/dashboard/client")}>Reservados</Button>
          {/* <Button color="inherit">Venues</Button> */}
          {/* <Button color="inherit">Our Vision</Button>
          <Button color="inherit">Industries</Button> */}
        </Box>
        <Box>
          {!user ? (
            <>
              <Button
                onClick={() => {
                  navigate("/register");
                }}
                color="inherit"
              >
                Registrarme
              </Button>
              <SearchButton
                onClick={() => {
                  navigate("/login");
                }}
              >
                Iniciar Sesión
              </SearchButton>
            </>
          ) : (
            <>
             <Box display="flex" alignItems="center">
            <Avatar
                src={profilePic}
                alt={user.email}
                sx={{ width: 30, height: 30, marginRight: 2 }}
              />
            <Button
              onClick={() => {
                supabase.auth.signOut().then(() => setUser(null));
              }}
              color="inherit"
            >
              Cerrar Sesión
            </Button>
            </Box>
            </>
          )}
        </Box>
      </Box>
    </AppBarStyled>
  );
};

export default Header;
