import React from 'react';
import { Box, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star'; // You can use any icon library or a custom SVG if needed

function StarRating({ rating }) {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <StarIcon sx={{ fontSize: 24, color: 'gold' }} />
      <Typography variant="body1">{rating}</Typography>
    </Box>
  );
}

export default StarRating;
