import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import {
  fetchAllArtists,
  fetchAllArtistsByGenre,
} from "../../functions/general";
import {
  getArtistsByGenre,
  getArtistsByGenres,
  getArtistsStageName,
  newGetArtistsByGenres,
} from "../../functions/artist";
import { useNavigate, useLocation } from "react-router-dom";

const SearchButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  backgroundColor: "#af8e56",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#af8e56",
  },
}));

const SearchBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#222",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5, 1),
  marginTop: theme.spacing(4),
  width: "100%",
}));

const SearchBar = ({ onSearch, searchCriteriaPage, setSearchCriteriaPage, isHomePage }) => {
  const [searchCriteria, setSearchCriteria] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Load search criteria from state if passed via navigate
  useEffect(() => {
    if (location.state?.searchCriteria) {
      setSearchCriteria(location.state.searchCriteria);
      searchArtists(location.state.searchCriteria); // Auto search if search criteria was passed
    }
  }, [location.state]);

  // Function to execute search and navigate if on homepage
  const searchArtists = async (criteria = searchCriteria) => {
    let results = [];
    if (isHomePage) {
      navigate("/search", { state: { searchCriteria: criteria } });
    } else {
      try {
        if (criteria === "") {
          results = await fetchAllArtists("artist");
        } else {
          const genres = criteria.split(",");
          if (genres.length > 1) {
            results = await getArtistsByGenres(genres);
            // results = await newGetArtistsByGenres(genres);
          } else {
            results = await getArtistsByGenre(criteria);
          }

          // Fallback to search by stage name if no results by genre
          if (results.length === 0) {
            results = await getArtistsStageName(criteria);
          }
        }
        setSearchCriteriaPage(criteria);
        onSearch(results);
      } catch (error) {
        console.error("Error during search:", error.message);
      }
    }
  };

  return (
    <SearchBox>
      <TextField
        variant="outlined"
        placeholder="Busca: Pop, Rock, Baladas, etc..."
        fullWidth
        value={searchCriteria}
        onChange={(e) => setSearchCriteria(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "#fff" }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <TuneIcon sx={{ color: "#fff" }} />
              </IconButton>
            </InputAdornment>
          ),
          style: { color: "#fff" },
        }}
        sx={{ backgroundColor: "#222" }}
      />
      <SearchButton variant="contained" onClick={() => searchArtists()}>
        Buscar
      </SearchButton>
    </SearchBox>
  );
};

export default SearchBar;
