import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom"; // Import useLocation to get the artist object from state
import StarIcon from "@mui/icons-material/Star";
import Header from "../components/Header/Header";
import { gold } from "../lib/colors";

// Import genre images (or dynamically based on artist's genres)
import balladImage from "../assets/images/categories/rock.png";
import popImage from "../assets/images/categories/pop.png";
import acousticImage from "../assets/images/categories/acoustic.png";
import BookNowButton from "../components/BookNowButton/BookNowButton";

import PriceCalculator from "../components/artistComponents/PriceCalculator";

const Root = styled("div")({
  backgroundColor: "#0e0e0e",
  color: "#fff",
  minHeight: "100vh",
  padding: "20px",
});

const ArtistName = styled(Typography)({
  fontSize: "48px",
  fontWeight: "bold",
  marginBottom: "20px",
});

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#1e1e1e",
  color: "#fff",
  marginBottom: "20px",
  borderRadius: "15px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
}));

const GenreCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#1e1e1e",
  color: "#fff",
  borderRadius: "15px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
  height: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const GenreImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "15px",
});

const ArtistProfileScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Retrieve the artist object from the location state
  const artist = location.state?.artist;

  // If no artist data is found, redirect back or show a message
  if (!artist) {
    // Optionally redirect to a different page
    return (
      <div>
        <h2>Artista no encontrado</h2>
        <Button onClick={() => navigate("/")}>Volver a Inicio</Button>
      </div>
    );
  }

  const artistCategories = [
    { name: "BALLAD", image: balladImage },
    { name: "POP", image: popImage },
    { name: "ACOUSTIC", image: acousticImage },
  ];

  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const showName = (str) => {
    const words = str.split(" ");
    if (words.length > 1) {
      const lastName = words.pop(); // Extract the last word
      const firstName = words.join(" "); // Join the rest of the words
      return (
        <>
          {capitalizeWords(firstName)}{" "}
          <span style={{ color: gold }}>{capitalizeWords(lastName)}</span>
        </>
      );
    }
    // If the name has only one word, return it normally
    return <>{capitalizeWords(str)}</>;
  };
  return (
    <>
      <Header />
      <Root>
        <ArtistName>{showName(artist.stageName)}</ArtistName>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <StyledCard>
              <CardContent>
                <img
                  src={artist.profile_picture}
                  alt={artist.stageName}
                  style={{
                    width: "100%",
                    height: "500px",
                    borderRadius: "10px",
                    objectFit: "contain",
                    // objectFit: "cover",
                    // objectFit: "fill",
                  }}
                />
              </CardContent>
            </StyledCard>
          </Grid>

          <Grid item xs={12} md={8}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  ¿Quién soy?
                </Typography>
                <Typography>{artist.description}</Typography>
              </CardContent>
            </StyledCard>

            <Grid container spacing={2}>
              {artistCategories.map((genre) => (
                <Grid item xs={4} key={genre.name}>
                  <GenreCard>
                    <GenreImage src={genre.image} alt={genre.name} />
                  </GenreCard>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} md={8}>
            <StyledCard>
              <CardContent>
                <img
                  src="https://ofaros-s3-storage.s3.us-west-1.amazonaws.com/vitalii-khodzinskyi-E5GMPmY_vVQ-unsplash+1.png"
                  alt="Guitar"
                  style={{ width: "60%", borderRadius: "10px" }}
                />
              </CardContent>
            </StyledCard>
          </Grid>

          <Grid item xs={12} md={4}>
            <StyledCard>
              <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                  <StarIcon sx={{ marginRight: 1, color: gold }} />
                  <Typography variant="h6">Escenarios destacados donde se ha presentado</Typography>
                </Box>
                <Typography variant="body2">{artist.venues}</Typography>
              </CardContent>
            </StyledCard>

            <StyledCard>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Configuración de la Presentación
                </Typography>
                <Typography variant="body2">
                  <strong>Tipo de Presentación:</strong> {artist.performanceType}
                  <br />
                  <strong>Acompañamiento:</strong> {artist.accompaniment}
                  <br />
                  <strong>Equipo:</strong> {artist.equipment}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
        <PriceCalculator rate={artist.rate} />
        <BookNowButton onClick={() => navigate("/faro_creation", { state: { artist } })} />
      </Root>
    </>
  );
};

export default ArtistProfileScreen;
