import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export const BookNowButton = ({ onClick }) => (
  <Button
    variant="contained"
    fullWidth
    onClick={onClick}
    sx={{
      backgroundColor: "#af8e56",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#8e7246",
      },
    }}
  >
    Contratar ahora
  </Button>
);

const ArtistCard = ({ artist }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const toggleDescription = () => setShowFullDescription(!showFullDescription);

  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleClick = () => {
    // Navigate to ArtistProfileScreen with the artist's ID (or any unique identifier)
    navigate(`/profile/artist`, { state: { artist } });
  };


  const truncateDescription = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + "...";
  };

  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  return (
    <Card sx={{ maxWidth: 345, borderRadius: 2, overflow: "hidden" }}>
      {/* Add an onClick event to the image to navigate to the profile screen */}
      <CardMedia
        component="img"
        height="140"
        image={artist.profile_picture}
        onClick={handleClick}
        sx={{ cursor: "pointer" }} // Add cursor pointer to indicate it's clickable
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {capitalizeWords(artist.stageName)}
        </Typography>
        {/* Render description only if it exists */}
        {artist.description && (
          <>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {showFullDescription
                ? artist.description
                : truncateDescription(artist.description, 150)}
            </Typography>

            {artist.description.length > 150 && (
              <Button
                size="small"
                onClick={toggleDescription}
                sx={{ color: "#af8e56", textTransform: "none" }}
              >
                {showFullDescription ? "See less" : "See more"}
              </Button>
            )}
          </>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          {/* Additional Info, e.g., Ratings or Price */}
        </Box>

        <BookNowButton onClick={() => navigate("/profile/artist", { state: { artist } })} />
      </CardContent>
    </Card>
  );
};

export default ArtistCard;
