import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import BookingCard from './BookingCard';

function Booking({artist}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingY: 4,
        paddingX: 4,
        borderRadius: 2,
        width: "100%",
        backgroundColor: 'grey.100'
      }}
    >
      <Typography
        variant="h2"
        sx={{
          width: '100%',
          fontSize: '2rem',
          fontWeight: 'bold',
          color: 'black',
          marginBottom: 2
        }}
      >
        Agendamiento
      </Typography>

      <BookingCard
        imageUrl={artist.profile_picture}
        specialization={artist.genre}
        name={artist.stageName}
        avatar_url={artist.profile_picture}
        // rating={4.9}
        // reviews={30}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 2,
          width: '100%',
          textAlign: 'center'
        }}
      >
        <Button
          variant="contained"
        
          sx={{ padding: '10px 20px', fontWeight: 'medium' , backgroundColor: "black", fontFamily: "Poppins"}}
        >
          Cambiar selección
        </Button>
      </Box>
    </Box>
  );
}

export default Booking;
