import React from 'react';
import { Box, Card, CardMedia, Typography, Button, Avatar } from '@mui/material';
import StarRating from './StarRating'; // Ensure this is updated to use Material-UI if necessary

function BookingCard({ imageUrl, specialization, name, rating, reviews,avatar_url }) {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        marginTop: 2,
        maxWidth: '100%',
        backgroundColor: 'white',
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'grey.200',
        minHeight: '322px',
        boxShadow: 1,
        width: '322px'
      }}
    >
      <CardMedia
        component="img"
        image={imageUrl}
        src={imageUrl}
        alt={`${name}'s profile`}
        sx={{
          width: '100%',
          height: 0,
          paddingTop: '65%', // Maintain aspect ratio
          borderRadius: 2,
          objectFit: 'contain'
        }}
      />
      <Box sx={{ padding: 2 }}>
        <Typography variant="body2" color="textSecondary">
          <span>Specialize in performing </span>
          <span style={{ fontWeight: 'bold' }}>{specialization}</span>
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 2 }}>
          <Avatar
            alt={`${name}'s avatar`}
            src={avatar_url}
            sx={{ width: 38, height: 38 }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="body2" color="textSecondary">
              <span style={{ fontWeight: 'bold' }}>{name}</span>
            </Typography>
            <StarRating rating={rating} />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/401c61f9e0d4cf56ee479cf3444c86aa96e26917d97866f9720bd703edf0077b?placeholderIfAbsent=true&apiKey=7ef19faf957d428881711f97cdd2525d"
                alt="Reviews icon"
                style={{ width: '9px', height: 'auto' }}
              />
              <Typography variant="body2">{reviews}</Typography>
            </Box>
          </Box>

        </Box>
      </Box>
    </Card>
  );
}

export default BookingCard;
