import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button as MUIButton } from '@mui/material';
import NequiTomas from '../../assets/images/NequiTomas.jpg';

const PaymentDialog = ({ open, handleClose, Valor, onNext }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: '1.5rem', fontWeight: 'bold', mt: 2 }}>
        {"Por favor realiza el pago"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: '1.25rem' }}>
          Envia ${Valor} COP a la cuenta de Nequi 300 123 4567 o a través del siguiente código QR
        </DialogContentText>
        <img
          src={NequiTomas}
          alt="Popup"
          style={{ width: '100%', height: 'auto' }}
        />
      </DialogContent>
      <DialogActions>
        <MUIButton onClick={handleClose} color="primary">
          Close
        </MUIButton>
        <MUIButton onClick={onNext} color="primary">
          Siguiente
        </MUIButton>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentDialog;
