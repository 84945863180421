import React, { useRef } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";

function UploadArea() {
  const fileInputRef = useRef(null);

  const handleFileSelect = (event) => {
    const files = event.target.files;
    // Process files here
    console.log(files);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    // Process files here
    console.log(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        px: 6,
        py: 24,
        mt: 4,
        width: '97%',
        minHeight: '271px',
        backgroundColor: 'white',
        borderRadius: 2,
        border: '2px dashed',
        borderColor: 'grey.500',
        textAlign: 'center',
        '&:hover': {
          borderColor: 'primary.main',
        },
        [theme => theme.breakpoints.down('md')]: {
          px: 5,
        },
      }}
      role="button"
      tabIndex="0"
      aria-label="Upload area. Drag your files or click to browse"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onClick={openFileDialog}
    >
      <IconButton
        sx={{
          mb: 2,
          width: 36,
          height: 36,
        }}
      >
        <CloudUploadIcon />
      </IconButton>
      <Typography
        variant="body2"
        color="text.secondary"
      >
        Drag your files or click to browse
      </Typography>
      <input
        type="file"
        multiple
        ref={fileInputRef}
        onChange={handleFileSelect}
        style={{ display: 'none' }}
      />
    </Box>
  );
}

export default UploadArea;
