// BookNowButton.js
import React from 'react';
import { Button } from '@mui/material';
import { gold, white } from '../../lib/colors';

const BookNowButton = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      fullWidth
      sx={{
        backgroundColor: gold,
        color: white,
        borderRadius: '15px', 
        padding: '12px 24px', 
        '&:hover': {
          backgroundColor: '#9a7209',
        },
      }}
    >
      Contratar ahora
    </Button>
  );
};

export default BookNowButton;
